import React, {forwardRef, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Functions from "../../../../../utils/Functions";
import {Box, Button, Chip, Grid, Popover, Stack, Typography} from "@mui/material";
import Utils from "../../../../../utils/Utils";
import {
    createInvoice,
    getInvoicesByCompany,
    getInvoicesByFreelance,
    getInvoicesByMission, modifyManyInvoiceStatus
} from "../../../../../services/invoiceService";
import {Table} from "../../../../Tables/Table";
import ResponsiveStepper from "../../../../UI/ResponsiveStepper";
import {MessageComponent} from "../../../../UI/MessageComponent";
import {FieldHolder} from "../../../FieldHolder";
import Popup from "../../../../Popup/Popup";
import {createFile} from "../../../../../services/fileService";
import missionRxjs from "../../../../../services/missionRxjs";
import {InvoiceDetailSection} from "./InvoiceDetailSection";
import "../missioncontent.css";
import {useToast} from "../../../../../context/ToastProvider";
import InvoiceSendFileForm from "./InvoiceSendFileForm";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);
export const InvoiceListSection = forwardRef((
    {
        formData,
        setFormData,
        onSectionClick,
        objectAttachedType,
        isSection = true,
        sessionType,
        canModify
    },
    ref
) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const token = useSelector((state) => state.auth.token);
    const showToast = useToast();

    const [stepInvoice, setStepInvoice] = useState(Functions.TEXT_DESCRIPTION);
    const [showInvoiceDialog, setShowInvoiceDialog] = useState(false);
    const [message, setMessage] = React.useState({});

    const defaultFormInvoice = {file: null, devise: "EUR"};
    const [invoicePaymentData, setInvoicePaymentData] = useState(defaultFormInvoice);
    const [invoiceFile, setInvoiceFile] = useState(null);
    const [selectedInvoice, setSelectedInvoice] = useState({});
    const [supportingDocument, setSupportingDocument] = useState(null);
    const deleteInvoiceFileRef = useRef();

    const [invoices, setInvoices] = useState([]);
    const tableRef = useRef();
    const fetchInvoicesRef = useRef();
    const [rowCount, setRowCount] = useState(0);
    const defaultFilterConfigs = {
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    };
    const [filterConfigs, setFilterConfigs] = useState(defaultFilterConfigs);
    const [invoiceRowSelectionModel, setInvoiceRowSelectionModel] = useState([]);

    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;
    const isFreelanceSession = sessionType === Functions.SESSION_TYPE_FREELANCE;

    const steps = [
        {key: Functions.TEXT_DESCRIPTION, name: 'Description'},
        {key: Functions.TEXT_DETAILS, name: 'Details'},
        {key: Functions.TEXT_MESSAGE, name: 'Message'},
    ];

    const columns = [
        {
            field: 'Invoice.name',
            type: 'string',
            headerName: t("Name"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                return <Box sx={{textWrap: "wrap"}}>
                    {row.name}
                </Box>;
            }
        },
        {
            field: 'Invoice.createdAt',
            type: 'date',
            headerName: isEnterpriseSession ? t("Received the") : t("Sent on"),
            minWidth: 120,
            flex: 1.2,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params, row) => row.createdAt && new Date(row.createdAt),
            renderCell: ({row}) => {
                return Utils.formatDate(row.createdAt);
            }
        },
        {
            field: 'dueDate',
            type: 'date',
            headerName: t("Due date"),
            minWidth: 120,
            flex: 1.2,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params, row) => row.dueDate && new Date(row.dueDate),
            renderCell: ({row}) => {
                return Utils.formatDate(row.dueDate);
            }
        },
        {
            field: 'amount',
            type: 'number',
            headerName: t("Amount"),
            minWidth: 120,
            flex: 1.2,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return `${row?.amount} ${Utils.getCurrencySymbol(row?.devise)}`;
            }
        },
        {
            field: 'status',
            type: 'singleSelect',
            headerName: t("Status"),
            minWidth: 120,
            flex: 1.2,
            headerAlign: 'center',
            align: 'center',
            valueOptions: Object.entries(Functions.STATUS_INVOICE).map(([value, label]) => ({
                value: value,
                label: t(label)
            })),
            renderCell: ({row}) => {
                const status = t(Functions.STATUS_INVOICE[row.status]);
                return <Chip
                    label={t(status)}
                    title={t(status)}
                    size="small"
                    className={Functions.STATUS_INVOICE_BG[row.status]}
                    sx={{whiteSpace: "nowrap"}}
                />
            }
        },
    ];

    if (!isSection) {
        columns.splice(1, 0,
            {
                field: 'Mission.name',
                type: 'string',
                headerName: t("Assignment"),
                minWidth: 150,
                flex: 1.5,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return row.Mission.name;
                }
            }
        );
        if (isEnterpriseSession) {
            columns.splice(2, 0,
                {
                    field: 'Mission.StaffedFreelance.User.fullName',
                    type: 'string',
                    headerName: t("Freelancer"),
                    minWidth: 120,
                    flex: 1.2,
                    headerAlign: 'center',
                    align: 'left',
                    renderCell: ({row}) => {
                        return row.Mission.StaffedFreelance?.User?.fullName;
                    }
                }
            );
        } else if (isFreelanceSession) {
            columns.splice(2, 0,
                {
                    field: 'Company.name',
                    type: 'string',
                    headerName: t("Company"),
                    minWidth: 120,
                    flex: 1.2,
                    headerAlign: 'center',
                    align: 'left',
                    renderCell: ({row}) => {
                        return row.Company?.name;
                    }
                }
            );
        }
    }

    useEffect(() => {
        const selectedFile = missionRxjs.getFactureFileObservable();
        const subscriptionFile = selectedFile.subscribe((data) => {
            if (data != null) {
                setInvoiceFile(data);
            }
        });

        return () => {
            subscriptionFile.unsubscribe();
        };
    }, []);

    useEffect(() => {
        fetchInvoicesRef.current(filterConfigs);
    }, [formData?.id, filterConfigs]);

    fetchInvoicesRef.current = async (query = {}) => {
        try {
            let fetchedInvoices;
            if (isSection) {
                if (formData?.id) {
                    fetchedInvoices = await getInvoicesByMission(formData.id, query, token);
                }
            } else {
                if (isFreelanceSession) {
                    fetchedInvoices = await getInvoicesByFreelance(query, token);
                } else {
                    fetchedInvoices = await getInvoicesByCompany(query, token);
                }
            }

            if (fetchedInvoices && !fetchedInvoices?.error) {
                setRowCount(fetchedInvoices.count);
                setInvoices(fetchedInvoices.data);
            }
        } catch (error) {
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setInvoicePaymentData({
            ...invoicePaymentData, [name]: value
        });
    };

    const handleBack = () => {
        switch (stepInvoice) {
            case Functions.TEXT_MESSAGE:
                setStepInvoice(Functions.TEXT_DETAILS);
                break;
            case Functions.TEXT_DETAILS:
                setStepInvoice(Functions.TEXT_DESCRIPTION);
                break;
            default:
                break;
        }
    };

    const handleNext = async () => {
        const currentStep = stepInvoice;
        let verifyData;

        const msgError = "Error saving data";

        verifyData = Utils.verifyDataFactureFreelance(
            invoicePaymentData,
            currentStep,
            currentStep === Functions.TEXT_DESCRIPTION ? invoiceFile : null
        );
        if (verifyData.type === "error") {
            setMessage(verifyData);
            return;
        }

        switch (currentStep) {
            case Functions.TEXT_DESCRIPTION:
            case Functions.TEXT_DETAILS:
                setStepInvoice(
                    currentStep === Functions.TEXT_DESCRIPTION
                        ? Functions.TEXT_DETAILS : Functions.TEXT_MESSAGE
                );
                setMessage({});
                break;
            case Functions.TEXT_MESSAGE:
                setMessage({type: "loading", message: t("Saving data")});

                const datas = invoicePaymentData;
                datas.missionId = formData.id;
                datas.companyId = formData.CompanyId;
                datas.freelanceProfileId = formData.StaffedFreelance.id;

                try {
                    if (invoiceFile.type !== "application/pdf") {
                        setMessage({
                            type: "error",
                            message: t("Format allowed") + " , PDF",
                        });
                        return false;
                    } else {
                        //## Create the file
                        if (invoiceFile) {
                            const fileData = {
                                typeFile: Functions.TEXT_DOCUMENT,
                                file: invoiceFile.theFile,
                            };
                            datas["file"] = await createFile(fileData, "", token);
                        }

                        //## Create the file
                        if (supportingDocument) {
                            const fileData = {
                                typeFile: Functions.TEXT_DOCUMENT,
                                file: supportingDocument.theFile,
                            };
                            datas["supportingDocument"] = await createFile(fileData, "", token);
                        }

                        const createdInvoice = await createInvoice(datas, token);
                        if (createdInvoice.error) {
                            setMessage({type: "error", message: msgError});
                        } else {
                            handleInvoiceDialogClose();

                            setStepInvoice(Functions.TEXT_DESCRIPTION);
                            setMessage({});

                            handleInitList();

                            const newSectionStatus = Utils.updateStatusSection(
                                formData.status_section,
                                Functions.SECTION_INVOICE_AND_PAYMENT
                            );
                            setFormData({...formData, status_section: newSectionStatus});
                        }
                    }
                } catch (error) {
                    setMessage({type: "error", message: msgError});
                }
                break;
            default:
                break;
        }
    };

    const handleInvoiceDialogClose = () => {
        if (deleteInvoiceFileRef.current) {
            deleteInvoiceFileRef.current.click();
        }
        setShowInvoiceDialog(false);
    };

    const handleDataGridChange = (filterConfigs) => {
        setFilterConfigs(filterConfigs);
    };

    const handleInvoiceCellClick = (row) => {
        if (isSection) {
            setSelectedInvoice(row);
        } else {
            navigate(`/invoices/${row.id}`);
        }
    };

    const handleRowSelectionModelChange = (newRowSelectionModel) => {
        setInvoiceRowSelectionModel(newRowSelectionModel);
    };

    const handleStatusChange = async (status) => {
        await changeManyInvoiceStatus({
            invoiceIds: invoiceRowSelectionModel,
            status,
            token,
            callback: (response) => {
                if (!response?.error) {
                    fetchInvoicesRef.current(filterConfigs);
                    handleCancelSelecting();
                    showToast(t("Invoice(s) successfully updated"));
                } else {
                    showToast(t("Error saving data"), "error");
                }
            }
        });
    };

    const handleSendInvoice = (success) => {
        if (success) {
            showToast(t(invoiceRowSelectionModel.length > 1 ? "The invoices have been sent" : "The invoice has been sent"));
            handleCancelSelecting();
        }
    };

    const handleCancelSelecting = () => {
        setInvoiceRowSelectionModel([]);
    };

    const handleInitList = () => {
        setSelectedInvoice({});
        setInvoicePaymentData(defaultFormInvoice);
        tableRef.current && tableRef.current.initTable();
        setFilterConfigs(defaultFilterConfigs);
    };

    if (ref) {
        ref.current = handleInitList;
    }

    return (
        <div className={`${isSection ? "card standardform-card card-padding-wide h-100" : ""}`}>
            {Object.keys(selectedInvoice).length === 0 && (
                <div className="common-board-container" style={{width: "100%"}}>
                    <Stack direction="row" justifyContent="space-between" spacing={1}>
                        {!isSection && (
                            <div className="pageheader">
                                <h1 className="pagetitle">
                                    {isFreelanceSession ? t("My invoices") : t("Invoices")}
                                    <p className="header-data-row-count">{rowCount}</p>
                                </h1>
                            </div>
                        )}
                        {isSection && (
                            <Typography variant="labelInfo">
                                {t("Invoices list")}
                            </Typography>
                        )}
                        {isSection && canModify &&
                            <>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setShowInvoiceDialog(true)}
                                >
                                    {t("Add an invoice")}
                                </Button>

                                <Popup
                                    open={showInvoiceDialog}
                                    onDialogClose={handleInvoiceDialogClose}
                                >
                                    <Box sx={{minWidth: {md: "40vw"}}}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item xs={12}>
                                                <ResponsiveStepper
                                                    steps={steps}
                                                    stepKey={stepInvoice}
                                                    onStepClick={(index) => (
                                                        setStepInvoice(steps[index]?.key)
                                                    )}
                                                />

                                                <MessageComponent
                                                    type={message?.type}
                                                    message={message?.message}
                                                    width="100%"
                                                />
                                            </Grid>

                                            {stepInvoice === Functions.TEXT_DESCRIPTION && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <FieldHolder
                                                            fielddesc={t("Document name")}
                                                            type="text"
                                                            name="name"
                                                            id="facture_name"
                                                            placeholder={t("Document name")}
                                                            value={invoicePaymentData?.name}
                                                            onChange={handleChange}
                                                            canModify={canModify}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FieldHolder
                                                            ref={deleteInvoiceFileRef}
                                                            fielddesc={t("File")}
                                                            type="file"
                                                            name="facture_file"
                                                            id="facture_file"
                                                            placeholder={t("File")}
                                                            value={invoicePaymentData?.file}
                                                            sibling="factureFile"
                                                            objectAttachedType={objectAttachedType}
                                                            canModify={canModify}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Stack direction="column" spacing={2}>
                                                            <FieldHolder
                                                                fielddesc={t("Mission")}
                                                                type="text"
                                                                placeholder={t("Mission")}
                                                                value={formData?.name}
                                                                objectAttachedType={objectAttachedType}
                                                                canModify={false}
                                                            />
                                                            <FieldHolder
                                                                fielddesc={t("Enterprise")}
                                                                type="text"
                                                                placeholder={t("Enterprise")}
                                                                value={formData?.Company?.name}
                                                                objectAttachedType={objectAttachedType}
                                                                canModify={false}
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                </>
                                            )}

                                            {stepInvoice === Functions.TEXT_DETAILS && (
                                                <>
                                                    <Grid item container xs={12} spacing={2}>
                                                        <Grid item xs={6}>
                                                            <FieldHolder
                                                                fielddesc={t("Amount")}
                                                                type="text"
                                                                name="amount"
                                                                id="quote_amount"
                                                                placeholder={t("Amount")}
                                                                formData={invoicePaymentData}
                                                                setFormData={setInvoicePaymentData}
                                                                value={invoicePaymentData?.amount}
                                                                onChange={handleChange}
                                                                canModify={true}
                                                                mandatoryFlag={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <FieldHolder
                                                                fielddesc={t("Exchange")}
                                                                type="dropdown"
                                                                set="Exchange"
                                                                name="devise"
                                                                id="quote_devise"
                                                                value={invoicePaymentData?.devise}
                                                                formData={invoicePaymentData}
                                                                setFormData={setInvoicePaymentData}
                                                                mandatoryFlag={true}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FieldHolder
                                                            fielddesc={t("Due date")}
                                                            type="date"
                                                            name="dueDate"
                                                            id="facture_due_date"
                                                            placeholder={t("Due date")}
                                                            formData={invoicePaymentData}
                                                            setFormData={setInvoicePaymentData}
                                                            value={invoicePaymentData?.dueDate}
                                                            onChange={handleChange}
                                                            canModify={canModify}
                                                            className={"input-mission-hw45"}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FieldHolder
                                                            fielddesc={t("Invoice receipt")}
                                                            type="file"
                                                            name="supportingDocument"
                                                            id="supporting_document"
                                                            value={invoicePaymentData?.supportingDocument}
                                                            objectAttachedType={objectAttachedType}
                                                            setFileInfo={setSupportingDocument}
                                                            canModify={canModify}
                                                        />
                                                    </Grid>
                                                </>
                                            )}

                                            {stepInvoice === Functions.TEXT_MESSAGE && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <FieldHolder
                                                            fielddesc={t("Message for the company")}
                                                            type="multiline"
                                                            name="commentFreelance"
                                                            id="facture_commentFreelance"
                                                            placeholder={t("Message for the company")}
                                                            value={invoicePaymentData?.commentFreelance || ""}
                                                            onChange={handleChange}
                                                            canModify={canModify}
                                                            className={"input-mission-hw45"}
                                                        />
                                                    </Grid>
                                                </>
                                            )}

                                            <Grid item xs={12}
                                                  sx={{display: "flex", justifyContent: "space-between", mt: 2}}>
                                                <Button
                                                    variant="contained"
                                                    color="light"
                                                    onClick={handleBack}
                                                >
                                                    {t("Previous")}
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={handleNext}
                                                >
                                                    {stepInvoice === Functions.TEXT_MESSAGE ? t("Send") : t("Next")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Popup>
                            </>
                        }
                    </Stack>

                    <Table
                        ref={tableRef}
                        columns={columns}
                        data={invoices}
                        rowCount={rowCount}
                        pageSize={pageSize}
                        filterConfigs={filterConfigs}
                        onChange={handleDataGridChange}
                        onCellClick={handleInvoiceCellClick}
                        checkboxSelection={isEnterpriseSession}
                        rowSelectionModel={invoiceRowSelectionModel}
                        onRowSelectionModelChange={handleRowSelectionModelChange}
                    />

                    {isEnterpriseSession && (
                        <SelectedInvoiceFooter
                            selectedInvoices={invoiceRowSelectionModel}
                            onChangeStatus={handleStatusChange}
                            onSendInvoice={handleSendInvoice}
                            onCancelSelecting={handleCancelSelecting}
                        />
                    )}
                </div>
            )}
            {Object.keys(selectedInvoice).length > 0 &&
                <InvoiceDetailSection
                    formData={formData}
                    selectedInvoice={selectedInvoice}
                    setSelectedInvoice={setSelectedInvoice}
                    sessionType={sessionType}
                    isSection={isSection}
                />
            }
        </div>
    );
});

export const SelectedInvoiceFooter = ({selectedInvoices, onChangeStatus, onSendInvoice, onCancelSelecting}) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [showSendInvoiceDialog, setShowSendInvoiceDialog] = useState(false);

    const handleStatusChange = (status) => {
        handleMenuClose();
        onChangeStatus(status);
    };

    const handleCancelSelecting = () => {
        onCancelSelecting();
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const openMenu = Boolean(anchorEl);
    const id = openMenu ? 'change-status-popover' : undefined;

    return (
        <>
            {selectedInvoices.length > 0 && (
                <Box id="selectedInvoiceFooter">
                    <Stack direction="row" alignItems="center" justifyContent="center" gap={2}>
                        <Typography sx={{fontWeight: "bold", textTransform: "lowercase"}}>
                            {selectedInvoices.length} {t("Invoice(s)")}
                        </Typography>
                        <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                            <>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleMenuClick}
                                    size="small"
                                >
                                    Changer le statut
                                </Button>
                                <Popover
                                    id={id}
                                    open={openMenu}
                                    anchorEl={anchorEl}
                                    onClose={handleMenuClose}
                                    anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                                    transformOrigin={{vertical: 'bottom', horizontal: 'left'}}
                                    sx={{mt: -1.5}}
                                >
                                    <Stack direction="column" spacing={1} sx={{p: 1.5, width: "157px"}}>
                                        {['Validated', 'Paid', 'Rejected'].map(status => {
                                            const label = t(Functions.STATUS_INVOICE[status]);
                                            return (
                                                <Chip
                                                    key={status}
                                                    label={`${label}(s)`}
                                                    title={`${label}(s)`}
                                                    size="small"
                                                    className={Functions.STATUS_INVOICE_BG[status]}
                                                    sx={{whiteSpace: "nowrap", cursor: "pointer"}}
                                                    onClick={() => handleStatusChange(status)}
                                                />
                                            );
                                        })}
                                    </Stack>
                                </Popover>
                            </>
                            <>
                                <Button
                                    aria-describedby={id}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setShowSendInvoiceDialog(true)}
                                    size="small"
                                >
                                    {t("Send by e-mail")}
                                </Button>
                                <Popup
                                    open={showSendInvoiceDialog}
                                    title={t("Send by e-mail")}
                                    onDialogClose={() => setShowSendInvoiceDialog(false)}
                                >
                                    <InvoiceSendFileForm
                                        invoiceIds={selectedInvoices}
                                        onSaveFinish={onSendInvoice}
                                        onClose={() => setShowSendInvoiceDialog(false)}
                                    />
                                </Popup>
                            </>
                            <Button
                                variant="outlined"
                                color="light"
                                onClick={handleCancelSelecting}
                                size="small"
                            >
                                {t("Cancel")}
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            )}

        </>
    );
};

export const changeManyInvoiceStatus = async ({invoiceIds, status, token, callback}) => {
    if (!invoiceIds.length) return;

    const invoiceDatas = invoiceIds.map((invoiceId) => ({id: invoiceId, status}));
    const response = await modifyManyInvoiceStatus({invoices: invoiceDatas}, token);
    callback && callback(response);
};