import React, {useRef, useState} from "react";
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {useDispatch} from 'react-redux';
import {saveToken} from '../../../utils/actions/authActions';
import './signupform.css';
import {createUser} from '../../../services/userService';
import CGU_et_CGV_Smarteem from '../../../assets/CGU_et_CGV_Smarteem.pdf';
import Mentions_d_information_Smarteem from '../../../assets/Mentions_d_information_Smarteem.pdf';

import {MessageComponent} from '../../UI/MessageComponent';
import {FieldHolder} from '../FieldHolder';
import {Button} from "@mui/material";
import Utils from "../../../utils/Utils";


export const SignupForm = (props) => {
    // Gestion des traductions
    const {t} = useTranslation();
    // // Gestion de l'envoi du formulaire signup
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirm: ''
    });

    const [companyData, setCompanyData] = useState({});
    const [isHidden, setIsHidden] = useState(false);
    const [message, setMessage] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const formRef = useRef(null);

    const requiredFields = ['firstName', 'lastName', 'email', 'password', 'passwordConfirm'];

    const toggleCompanyNameVisibility = (isCheckForm = false) => {
        if (isCheckForm && !checkForm()) {
            return;
        }
        setIsHidden(!isHidden);
        setMessage({});
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleCompanyChange = (e) => {
        const {name, value} = e.target;
        setCompanyData({...companyData, [name]: value});
    };

    const checkForm = () => {
        const {valid, errors} = Utils.validateRequiredFields(formData, requiredFields);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        return valid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!checkForm()) {
            return;
        }

        (async () => {
            try {
                const responseData = await createUser(formData, companyData);
                if (responseData.success) {
                    dispatch(
                        saveToken({
                            token: responseData.token,
                            userId: responseData.userId,
                            userFirstName: responseData.userFirstName,
                            userLastName: responseData.userLastName,
                            userEmail: responseData.userEmail,
                            freelanceId: responseData.freelanceId,
                            enterpriseId: responseData.enterpriseId,
                            companyId: responseData.companyId,
                            companyName: responseData.companyName,
                            companyRole: responseData.companyRole,
                            userType: responseData.userType,
                            displayConfig: responseData.displayConfig,
                            fullAccess: false
                        })
                    );
                    navigate('/onboarding');
                } else {
                    console.log(responseData);
                    setMessage({type: 'error', message: responseData?.error});
                }
            } catch (error) {
                setMessage({type: 'error', message: error.message});
            }
        })();
    };

    return (
        <>
            <div className="centerformcard">
                <form
                    ref={formRef}
                    className="inputform standardform"
                    id="signupform"
                    onSubmit={handleSubmit}
                    noValidate
                >
                    {!isHidden &&
                        <>
                            <FieldHolder
                                fielddesc={t("First name")}
                                type="text"
                                name="firstName"
                                id="firstName"
                                placeholder={t("Jean")}
                                value={formData.firstName}
                                onChange={handleChange}
                                mandatoryFlag={true}
                                error={formErrors.firstName}
                            />
                            <FieldHolder
                                fielddesc={t("Last name")}
                                type="text"
                                name="lastName"
                                id="lastName"
                                placeholder={t("Dupont")}
                                value={formData.lastName}
                                onChange={handleChange}
                                mandatoryFlag={true}
                                error={formErrors.lastName}
                            />
                            <FieldHolder
                                fielddesc={t("Email")}
                                type="text"
                                name="email"
                                id="email"
                                placeholder={t("")}
                                value={formData.email}
                                onChange={handleChange}
                                mandatoryFlag={true}
                                error={formErrors.email}
                            />
                            <FieldHolder
                                fielddesc={t("Password")}
                                type="password"
                                name="password"
                                id="password"
                                placeholder={t("************")}
                                value={formData.password}
                                onChange={handleChange}
                                mandatoryFlag={true}
                                error={formErrors.password}
                            />
                            <FieldHolder
                                fielddesc={t("Confirm password")}
                                type="password"
                                name="passwordConfirm"
                                id="passwordConfirm"
                                placeholder={t("************")}
                                value={formData.passwordConfirm}
                                onChange={handleChange}
                                mandatoryFlag={true}
                                error={formErrors.passwordConfirm}
                            />
                            <a
                                className='indexlink'
                                href={CGU_et_CGV_Smarteem}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t("By creating my account, I accept Smarteem's terms and conditions.")}
                            </a>
                            <a
                                className='indexlink'
                                href={Mentions_d_information_Smarteem}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t("To find out more about how your personal data is managed and to exercise your rights, please refer to the Smarteem information notice.")}
                            </a>
                            <div className='buttonbox'>
                                <Button
                                    type="submit"
                                    name="main_type"
                                    value="Enterprise"
                                    variant="contained"
                                    size="large"
                                    onClick={() => toggleCompanyNameVisibility(true)}
                                    sx={{width: "48%"}}
                                >
                                    {t("Enterprise")}
                                </Button>

                                <Button
                                    type="submit"
                                    name="main_type"
                                    value="Freelance"
                                    variant="contained"
                                    size="large"
                                    onClick={handleChange}
                                    sx={{width: "48%"}}
                                >
                                    {t("Freelancer")}
                                </Button>
                            </div>
                            <MessageComponent type={message.type} message={message.message} width="100%"/>
                            <div className='field'>
                                <p className='indexlink'
                                   onClick={props.toggleLoginVisibility}>{t("Already have an account? Connect")}</p>
                            </div>
                        </>
                    }
                    {isHidden &&
                        <>
                            <div className='field'>
                                <p>{t("Company name")}</p>
                                <input
                                    type='text'
                                    name='name'
                                    id='01'
                                    placeholder='Bluecube consulting'
                                    className='input inputindex'
                                    value={companyData.name}
                                    onChange={handleCompanyChange}
                                />
                            </div>
                            <div className='field'>
                                <Button
                                    type="submit"
                                    name="main_type"
                                    value="Enterprise"
                                    variant="contained"
                                    size="large"
                                    onClick={handleChange}
                                    fullWidth
                                >
                                    {t("Validate and create company")}
                                </Button>
                            </div>
                            <MessageComponent type={message.type} message={message.message} width="90%"/>
                            <div className='field'>
                                <p className='indexlink' onClick={toggleCompanyNameVisibility}>{t("Return")}</p>
                            </div>
                        </>
                    }
                </form>
            </div>
        </>
    );
};