import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

import './display.css';
import {Chip, FormControlLabel, Link, Stack, Switch, Typography} from "@mui/material";
import parameters from "../../utils/parameters.json";
import {fetchFileInfo} from "../../services/fileService";
import {useSelector} from "react-redux";
import {MdUploadFile} from "react-icons/md";
import S3Image from "../UI/S3Image";
import Functions from "../../utils/Functions";
import useFileOpener from "../../hooks/customHooks";
import {FaCircleNotch} from "react-icons/fa";
import Utils from "../../utils/Utils";
import TextTruncate from "react-text-truncate";
import {useFormStore} from "../../store/formStore";

export const Display = (
    {
        set,
        datas,
        backgroundColor,
        className = '',
        type,
        value = '',
        toTranslate,
        placeholder,
        displayPlaceholder,
        displaySkeleton = true,
        ...props
    }
) => {
    const {t} = useTranslation();
    const [options, setOptions] = useState([]);
    const {showMandatoryMark} = useFormStore();

    let style;
    value = props.defaultValue ?? value;

    useEffect(() => {
        (async function () {
            // Créez une nouvelle liste d'options en utilisant map
            if (typeof datas !== "undefined" && datas.length) {
                setOptions(
                    datas.map((item) => ({
                        value: item.id,
                        label: `${item.name}`
                    }))
                );
            } else {
                // Utilisation des options définies dans le JSON
                const jsonOptions = parameters[set]
                if (jsonOptions && typeof jsonOptions !== "undefined") {
                    setOptions(
                        Object.keys(jsonOptions).map((key) => ({
                            value: key,
                            label: t(jsonOptions[key].nativetext)
                        }))
                    );
                }
            }
        })()
    }, [t, set, datas]);

    const getDisplayValue = (type) => {
        let res;

        switch (type) {
            case 'file':
                return <DisplayFile value={value} {...props} />
            case 'image':
                return getDisplayImage();
            case 'date':
                res = Utils.formatDate(value);
                break;
            case 'checkbox':
                const answer = Boolean(value) ? t("Yes") : t("No");
                res = <FormControlLabel disabled control={<Switch checked={Boolean(value)}/>}
                                        label={answer}/>
                break;
            case 'dropdown':
                if (props.ismulti && Array.isArray(value)) {
                    const options = value.map((option) => {
                        const name = option.name ?? option.label ?? "";
                        return <Chip key={name} label={name} size={props.size ?? "medium"}/>
                    });
                    res = <Stack direction="row"
                                 sx={{flexWrap: "wrap", gap: "5px"}}>{options.length > 0 ? options : "-"}</Stack>
                } else if (!Array.isArray(value)) {
                    const option = options.find((opt) => opt.value === value);
                    res = option?.label;
                }
                break;
            case 'url':
                if (value) {
                    res = <MyLink url={value}/>
                }
                break;
            case 'textTruncate':
                if (value) {
                    value = value.replace(/\n/g, '');
                    res = <TextTruncate
                        line={2}
                        truncateText="..."
                        text={value}
                    />
                }
                break;
            case 'wysiwyg':
                style = {width: "100%"};
                res = <div className="ql-wysiwyg" dangerouslySetInnerHTML={{__html: value || "-"}}/>
                break;
            default:
                res = value;
                break;
        }

        // Translation of the text
        if (toTranslate) {
            res = t(`${res}`);
        }

        // Display of a specific placeholder for the display
        if (!res) {
            res = displayPlaceholder ? placeholder : "";
            res = res ? res : "-";
        }

        return <Stack direction="column" alignItems="flex-start" className={className} spacing={0.5}
                      sx={{maxWidth: '100%'}}>
            {props.label &&
                <Typography variant="label">
                    <span>{props.label}</span>
                    {showMandatoryMark && props.required && (<span className="mandatory-text"> *</span>)}
                </Typography>
            }
            <Stack className="MuiTypography-body1" sx={style}>
                {res}{value ? (props.unit || "") : ""}
            </Stack>
        </Stack>
    }

    const getDisplayImage = () => {
        return <S3Image value={value} displaySkeleton={displaySkeleton} className="file-image"/>
    }

    return <>
        {getDisplayValue(type)}
    </>
}

export const DisplayFile = ({value, ...props}) => {
    const token = useSelector((state) => state.auth.token);
    const [fileInfo, setFileInfo] = useState("");
    const {loading, openFileInNewTab} = useFileOpener();
    const {showMandatoryMark} = useFormStore();

    useEffect(() => {
        if (value !== undefined && value && value !== true) {
            (async () => {
                try {
                    const fetchedFileInfo = await fetchFileInfo(value, token);
                    setFileInfo(fetchedFileInfo);
                } catch (error) {
                }
            })();
        }
    }, [token, value]);

    const handleFileOpen = async (value) => {
        await openFileInNewTab(value, Functions.TEXT_DOCUMENT, token);
    }

    return <>
        <Stack direction="column" className="w-100" alignItems="flex-start" spacing={0.5}>
            {props.label &&
                <Typography variant="label">
                    <span>{props.label}</span>
                    {showMandatoryMark && props.required && (<span className="mandatory-text"> *</span>)}
                </Typography>
            }
            <Stack direction="row" className="w-100">
                {fileInfo &&
                    <Stack direction="row" className="w-100" alignItems="center" spacing={0.5}>
                        <MdUploadFile
                            className="Mui-icon"
                            size={20}
                            style={{cursor: "pointer", minWidth: "20px"}}
                            onClick={() => handleFileOpen(value)}
                        />
                        <Typography className="Mui-textTruncate">{fileInfo.name}</Typography>
                        {loading && <FaCircleNotch size={20} className="spinner"/>}
                    </Stack>
                }
                {!fileInfo && <span>-</span>}
            </Stack>
        </Stack>
    </>
}

export const MyLink = ({url}) => {
    const correctedUrl = Utils.formatUrl(url);
    return (
        <Link
            href={correctedUrl}
            underline="none"
            target="_blank"
            rel="noopener noreferrer"
            style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                display: "block",
                width: "100%"
            }}
        >{url}</Link>
    );
};