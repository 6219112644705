import {create} from 'zustand';
import Utils from "../utils/Utils";
import {updateFreelanceProfile} from "../services/freelanceProfileService";
import Functions from "../utils/Functions";
import {createFile} from "../services/fileService";

export const useFreelanceProfileStore = create((set) => ({
    freelanceEditPermissions: [],
    freelanceData: {},
    setFreelanceEditPermissions: (data) => set({freelanceEditPermissions: data}),
    setFreelanceData: (data) => set({freelanceData: data}),
    handleFreelanceDataChange: (e) => set((state) => {
        const {name, value} = e.target;
        return {
            freelanceData: Utils.updateNestedField(state.freelanceData, name, value)
        };
    })
}));


export const handleFreelanceProfileSave = async (
    {
        token,
        freelanceProfileData,
        callback,
        requiredFields = [],
        freelanceProfileFiles = []
    }
) => {
    try {
        let data = {...freelanceProfileData};

        const {valid, errors} = Utils.validateRequiredFields(data, requiredFields);
        if (!valid) {
            return {error: true, data: errors};
        }

        for (const freelanceProfileFile of freelanceProfileFiles) {
            if (freelanceProfileFile) {
                const fieldName = freelanceProfileFile.name;
                const file = freelanceProfileFile.theFile;

                if (file !== null) {
                    const fileData = {
                        typeFile: Utils.isImage(file.name) ? Functions.TEXT_PROFILE_PICTURE : Functions.TEXT_DOCUMENT,
                        [fieldName]: file,
                    };

                    const fileResult = await createFile(fileData, "", token);
                    if (!fileResult?.error) {
                        data = Utils.updateNestedField(data, fieldName, fileResult);
                    }
                }
            }
        }

        // Gestion des Documents (Supprimer les lignes vides)
        for (const documentType of ["FreelanceProfileDocuments"]) {
            data[documentType] = data[documentType].filter(
                (doc) => doc.file && doc.name
            );
        }

        delete data?.availabilityUpdatedUserId;
        delete data?.availabilityUpdatedAt;
        delete data?.AvailabilityUpdatedUser;
        delete data?.CompanyToFreelanceRelations;
        delete data?.FreelancePortfolios;
        delete data?.FreelanceProfileAvailabilities;
        delete data?.FreelanceProfileNotAvailabilities;

        const result = await updateFreelanceProfile(data?.id, data, token);
        if (!result.error) {
            callback && callback();
        }

        return {success: true, data};
    } catch (error) {
        return {success: false};
    }
};