import React, {useCallback, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {Trans, useTranslation} from "react-i18next";
import {AppLayout} from "../../../components/UI/AppLayout";

import "../../page.css";
import {Table, TableActionMenu} from "../../../components/Tables/Table";
import Utils from "../../../utils/Utils";
import Functions from "../../../utils/Functions";
import {Alert, Box, Button, Chip, Grid, Stack, Typography} from "@mui/material";
import Popup from "../../../components/Popup/Popup";
import {
    deleteCooptation,
    fetchFreelanceCooptations
} from "../../../services/collaborationService";
import CooptationForm from "./CooptationForm";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const CooptationBoard = () => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const freelanceId = connectedUser?.freelanceId;

    const tableRef = useRef();
    const defaultValue = {
        firstName: '',
        lastName: '',
        email: null,
        phoneNumber: '',
        workedWithFreelancer: '',
        language: '',
        recommendationProbability: '',
        recommendationDescription: '',
    };
    const [isEditingMode, setEditingMode] = useState(false);
    const [editingData, setEditingData] = useState(defaultValue);
    const [loading, setLoading] = useState(true);
    const [rowCount, setRowCount] = useState(0);
    const [data, setData] = useState([]);
    const defaultFilterConfigs = {
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    };
    const [filterConfigs, setFilterConfigs] = useState(defaultFilterConfigs);

    const isFreelanceSession = connectedUser.sessionType === Functions.SESSION_TYPE_FREELANCE;

    const columns = [
        {
            field: 'FreelanceProfile.User.fullName',
            headerName: t("Name"),
            type: 'string',
            minWidth: 250,
            flex: 2.5,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                return (
                    <Box sx={{textWrap: "wrap"}}>
                        {row?.FreelanceProfile?.User?.fullName}
                    </Box>
                );
            }
        },
        {
            field: 'FreelanceProfile.User.email',
            headerName: t("Email"),
            type: 'string',
            minWidth: 250,
            flex: 2.5,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                const email = row?.FreelanceProfile?.User?.email;
                return (
                    <div className="MuiDataGrid-cellContent" title={email} role="presentation">
                        {email}
                    </div>
                );
            }
        },
        {
            field: 'FreelanceProfile.User.phoneNumber',
            headerName: t("Phone number"),
            type: 'string',
            minWidth: 180,
            flex: 1.8,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return (
                    <Box sx={{textWrap: "wrap"}}>
                        {row?.FreelanceProfile?.User?.phoneNumber}
                    </Box>
                );
            }
        },
        {
            field: 'workedWithFreelancer',
            type: 'boolean',
            headerName: t("Collaboration"),
            minWidth: 180,
            flex: 1.8,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return row.workedWithFreelancer ? t("Yes") : t("No")
            }
        },
        {
            field: 'recommendationProbability',
            headerName: t("Recommendation"),
            minWidth: 180,
            flex: 1.8,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                const nps = row?.recommendationProbability;
                return nps !== null && typeof nps !== "undefined"
                    ? <Chip
                        label={+parseFloat(nps).toFixed(1)}
                        className={Utils.getRecommendationStatus(parseFloat(nps))}
                    />
                    : "-";
            }
        },
        {
            field: 'action',
            headerName: '',
            minWidth: 80,
            flex: 0.8,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return (
                    <TableActionMenu
                        row={row}
                        onEdit={!row.onboardingComplete ? handleEdit : null}
                        onDelete={handleDelete}
                    />
                );
            },
        }
    ];

    const loadCooptations = useCallback((query = {}) => {
        try {
            if (isFreelanceSession) {
                fetchFreelanceCooptations(freelanceId, query, token).then((fetchedData) => {
                    if (!fetchedData?.error) {
                        setRowCount(fetchedData.count);
                        setData(fetchedData.data);
                    }
                    setLoading(false);
                });
            }
        } catch (error) {
            console.error(error);
        }
    }, [token, isFreelanceSession, freelanceId]);

    useEffect(() => {
        loadCooptations(filterConfigs);
    }, [filterConfigs, loadCooptations]);

    const handleEdit = (id) => {
        const index = data.findIndex((cooptation) => cooptation.id === id);
        if (index > -1) {
            const _data = data[index];
            const userData = _data?.FreelanceProfile?.User;
            setEditingData({
                id: _data.id,
                firstName: userData?.firstName,
                lastName: userData?.lastName,
                email: userData?.email,
                phoneNumber: userData?.phoneNumber,
                workedWithFreelancer: _data.workedWithFreelancer,
                language: _data.language,
                recommendationProbability: _data.recommendationProbability,
                recommendationDescription: _data.recommendationDescription,
            });
            setEditingMode(true);
        }
    };

    const handleDelete = async (id) => {
        await deleteCooptation(id, token);
        loadCooptations(filterConfigs);
    };

    const handleCreate = () => {
        setEditingData(defaultValue);
        setEditingMode(true);
    };

    const handleDialogClose = () => {
        setEditingMode(false);
    };

    const handleSaveFinish = (success) => {
        if (success) {
            loadCooptations(filterConfigs);
            handleDialogClose();
        }
    };

    const handleDataGridChange = (filterConfigs) => {
        setFilterConfigs(filterConfigs);
    };

    return (
        <AppLayout>
            <Box sx={{width: {xs: '100%', md: 'calc(57% + 302px)'}}}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Box className="box-item standardform-card card-padding-wide" sx={{alignItems: "center"}}>
                            <Stack
                                direction="column"
                                spacing={3}
                                sx={{width: {xs: "100%", md: "68%"}}}
                            >
                                <Typography variant="h1" color="primary">
                                    {t("Recommend 5 freelancers from your community and receive a gift card!")}
                                </Typography>
                                <Typography className="text-center">
                                    <Trans
                                        t={t}
                                        i18nKey={
                                            "Grow the Smarteem community and introduce us to the freelancers you personally recommend. \n" +
                                            "\n" +
                                            "If you recommend 5 freelancers, you will receive a <0>{{link}}</0> gift card worth 20 euros. \n" +
                                            "\n" +
                                            "There's no limit: for every 5 freelancers you recommend, you'll receive a new gift card!"
                                        }
                                        values={{link: "Ethi'Kdo"}}
                                        components={[<a
                                            className=""
                                            href="https://www.ethikdo.co/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        ></a>]}
                                    />
                                </Typography>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="column" spacing={2}>
                            <Stack alignItems="end">
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={handleCreate}
                                >{t("Hiring a freelancer")}</Button>

                                <Popup
                                    title={t("Hiring a freelancer")}
                                    open={isEditingMode}
                                    onDialogClose={handleDialogClose}
                                >
                                    <CooptationForm
                                        cooptation={editingData}
                                        onSaveFinish={handleSaveFinish}
                                    />
                                </Popup>
                            </Stack>
                            <Box className="box-item standardform-card card-padding-wide">
                                <Typography variant="label">{t("Referred freelancers")}</Typography>
                                <div className="table-layout">
                                    <Stack direction="column" className="w-100">
                                        <Table
                                            ref={tableRef}
                                            columns={columns}
                                            data={data}
                                            rowCount={rowCount}
                                            pageSize={pageSize}
                                            filterConfigs={filterConfigs}
                                            onChange={handleDataGridChange}
                                            hideFilter={true}
                                        />
                                        {!loading && !Boolean(data.length) &&
                                            <Alert
                                                icon={false}
                                                severity="info"
                                                sx={{position: 'relative', marginTop: "-10px", width: "100%"}}
                                            >{t("No referred freelancers")}</Alert>
                                        }
                                    </Stack>
                                </div>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </AppLayout>
    );
};

